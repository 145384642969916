import * as React from 'react';

/**
 * Component properties.
 */
export interface IProps {
	id?: string;
	className?: string;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function Section({ id, className = ``, children }: IProps) {
	return (
		<section
			id={id}
			className={`w-full px-4 sm:px-14 pt-14 pb-4 sm:pb-14 relative overflow-hidden first:pt-26 ${className}`}
		>
			{children}
		</section>
	);
}
