import { motion } from 'framer-motion';
import * as React from 'react';
import * as config from '../config/constants';
import type { AnimationControls, Variants } from 'framer-motion';

/**
 * Represents the animation direction for the button.
 */
export type Direction = `UP` | `DOWN` | `RIGHT` | `LEFT`;

/**
 * Represents the dictionary of variants for each animation direction.
 */
type VariantsDict = {
	[key in Direction]: Variants;
};

/**
 * Animation variants.
 */
const STARTING_OFFSET = 50;
const VARIANTS: VariantsDict = {
	UP: {
		initial: {
			y: STARTING_OFFSET,
			opacity: 0,
		},
		pageLoad: {
			y: 0,
			opacity: 1,
			transition: { ease: `easeOut`, duration: config.ANIMATION_DURATION, delay: config.PAGE_LOAD_DURATION },
		},
		navigationExit: {
			y: STARTING_OFFSET,
			opacity: 0,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
		navigationEntry: {
			y: 0,
			opacity: 1,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
	},
	DOWN: {
		initial: {
			y: -STARTING_OFFSET,
			opacity: 0,
		},
		pageLoad: {
			y: 0,
			opacity: 1,
			transition: { ease: `easeOut`, duration: config.ANIMATION_DURATION, delay: config.PAGE_LOAD_DURATION },
		},
		navigationExit: {
			y: -STARTING_OFFSET,
			opacity: 0,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
		navigationEntry: {
			y: 0,
			opacity: 1,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
	},
	RIGHT: {
		initial: {
			x: -STARTING_OFFSET,
			opacity: 0,
		},
		pageLoad: {
			x: 0,
			opacity: 1,
			transition: { ease: `easeOut`, duration: config.ANIMATION_DURATION, delay: config.PAGE_LOAD_DURATION },
		},
		navigationExit: {
			x: -STARTING_OFFSET,
			opacity: 0,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
		navigationEntry: {
			x: 0,
			opacity: 1,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
	},
	LEFT: {
		initial: {
			x: STARTING_OFFSET,
			opacity: 0,
		},
		pageLoad: {
			x: 0,
			opacity: 1,
			transition: { ease: `easeOut`, duration: config.ANIMATION_DURATION, delay: config.PAGE_LOAD_DURATION },
		},
		navigationExit: {
			x: STARTING_OFFSET,
			opacity: 0,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
		navigationEntry: {
			x: 0,
			opacity: 1,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
	},
};

/**
 * Component properties.
 */
export interface IProps {
	ctrlAnimateTransition: AnimationControls;
	direction: Direction;
	href: string;
	Icon: Function;
}

/**
 * Component.
 */
export default function ContactButton({ ctrlAnimateTransition, direction, href, Icon }: IProps) {
	const variants = VARIANTS[direction];

	return (
		<motion.a
			initial="initial"
			animate={ctrlAnimateTransition}
			variants={variants}
			whileHover={{ scale: 1.1 }}
			href={href}
			target="_blank"
			className="opacity-0 m-5 transition-colors duration-200"
		>
			<Icon className="h-20 fill-white hover:fill-sky-300" />
		</motion.a>
	);
}
