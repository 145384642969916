import * as React from 'react';
import { motion } from 'framer-motion';
import * as config from '../config/constants';
import type { Variants } from 'framer-motion';

const STARTING_OFFSET = -50;
const STAGGER_DELAY = 0.1;

/**
 * Returns the animation variants for the given weight of the component.
 * @param weight - An ordinal value.
 */
function getVariants(weight: number): Variants {
	const staggerDelay = STAGGER_DELAY * weight;

	return {
		initial: {
			y: STARTING_OFFSET,
			opacity: 0,
		},
		pageLoad: {
			y: 0,
			opacity: 1,
			transition: {
				ease: `easeOut`,
				duration: config.ANIMATION_DURATION,
				delay: config.PAGE_LOAD_DURATION + staggerDelay,
			},
		},
		navigationExit: {
			y: STARTING_OFFSET,
			opacity: 0,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 + staggerDelay },
		},
		navigationEntry: {
			y: 0,
			opacity: 1,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 + staggerDelay },
		},
	};
}

/**
 * Component properties.
 */
export interface IProps {
	ctrlAnimateTransition: any;
	weight: number;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function BlogPostCard({ ctrlAnimateTransition, weight, children }: IProps) {
	const variants = getVariants(weight);

	return (
		<motion.div
			initial="initial"
			animate={ctrlAnimateTransition}
			variants={variants}
			className="first:mt-0 last:mb-0 my-6 sm:my-8 sm:first:mt-8 sm:last:mb-8"
		>
			<div className="bg-blue-300 bg-opacity-20 backdrop-blur-lg p-5 rounded-lg text-white">{children}</div>
		</motion.div>
	);
}
