import { motion } from 'framer-motion';
import * as React from 'react';

/**
 * Represents the type of pill tag shape.
 */
export type PillType = `TAG_RIGHT` | `ROUNDED`;

/**
 * Component properties.
 */
export interface IProps {
	type?: PillType;
	bgCol?: string;
	fgCol?: string;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function Pill({ type = `TAG_RIGHT`, bgCol = `bg-gray-400`, fgCol = `text-black`, children }: IProps) {
	const mainCls = type === `ROUNDED` ? `rounded-xl` : ``;

	return (
		<motion.div
			className={`inline-flex mx-1 mt-[0.15rem] h-5 first:ml-0 last:mr-0 text-xs font-light select-none ${fgCol}`}
		>
			{type === `TAG_RIGHT` && <motion.div className={`w-2 pill-left ${bgCol}`}></motion.div>}
			<motion.div className={`flex-1 flex px-2 ${mainCls} ${bgCol}`}>
				<span className="m-auto">{children}</span>
			</motion.div>
			{type === `TAG_RIGHT` && <motion.div className={`w-2 pill-right ${bgCol}`}></motion.div>}
		</motion.div>
	);
}
