import * as React from 'react';
import PillBar from './PillBar';
import Pill from './Pill';
import { Tag } from '../lib/tags';
import { formatDecimal, formatDate } from '../lib/utilities';
import DotSeparator from './DotSeparator';
import type { Author } from '../lib/authors';

/**
 * Component properties.
 */
export interface IProps {
	postAuthors: Author[];
	firstPublished: string;
	lastUpdated: string;
	timeToRead: number;
	wordCount: number;
	tags: Tag[];
}

/**
 * Component.
 */
export default function BlogPostPills({
	postAuthors,
	firstPublished,
	lastUpdated,
	timeToRead,
	wordCount,
	tags,
}: IProps) {
	const firstPublishedFormatted = formatDate(firstPublished);
	const lastUpdatedFormatted = formatDate(lastUpdated);
	const hasChanged = lastUpdatedFormatted !== firstPublishedFormatted;

	return (
		<>
			<PillBar className="flex !px-0 mb-6 text-white text-xs font-light select-none">
				<span>{postAuthors[0].name}</span>
				<DotSeparator />
				<span>{firstPublishedFormatted}</span>
				{hasChanged && (
					<span className="hidden sm:inline">
						<DotSeparator />
						<span>updated {lastUpdatedFormatted}</span>
					</span>
				)}
				<DotSeparator />
				<span>
					<span>{timeToRead} min</span>
					<span className="hidden md:inline">&nbsp;read</span>
				</span>
				<DotSeparator />
				<span>{formatDecimal(wordCount)} words</span>
			</PillBar>
			{!!tags.length && (
				<PillBar className="flex !px-0 mb-6">
					{tags.map((tag, index) => (
						<Pill key={index} type="TAG_RIGHT" bgCol={tag.colourName}>
							{tag.label}
						</Pill>
					))}
				</PillBar>
			)}
		</>
	);
}
