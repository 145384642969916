import * as config from '../config/constants';
import type { AnimationProps } from 'framer-motion';

/**
 * Returns variants for link buttons.
 * @param opacity - The amount of opacity to apply to the button when visible.
 */
export function getButtonVariants(opacity: number = 0.5): AnimationProps[`variants`] {
	return {
		initial: {
			opacity: 0,
		},
		pageLoad: {
			opacity,
			transition: {
				ease: `easeOut`,
				duration: config.ANIMATION_DURATION,
				delay: config.PAGE_LOAD_DURATION,
			},
		},
		navigationExit: {
			opacity: 0,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
		navigationEntry: {
			opacity,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
		},
	};
}
