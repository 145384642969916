import * as React from 'react';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { setupPage } from '../lib/navigation';
import IconExclamationCircle from '../images/exclamation-circle.svg';

const Page = () => {
	const [ctrlAnimateTransition, onNavigationExit, onNavigationEntry] = setupPage(useEffect, useAnimation);

	return (
		<Layout
			pageTitle="404 Page Not Found"
			pageDescription="Whoops! This page doesn't appear to be here, I wonder where it went?"
			ctrlAnimateTransition={ctrlAnimateTransition}
			onNavigationExit={onNavigationExit}
			onNavigationEntry={onNavigationEntry}
		>
			<Section className="h-screen flex">
				<div className="m-auto">
					<IconExclamationCircle className="inline-block text-red-400 opacity-80 w-16 mr-3 align-middle animate-pulse" />
					<div className="inline-block text-white font-thin text-2xl align-middle">Whoops! Can't find that...</div>
				</div>
			</Section>
		</Layout>
	);
};

export default Page;
