import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Layout from '../components/Layout';
import Section from '../components/Section';
import ContactButton from '../components/ContactButton';
import MailchimpForm from '../components/MailchimpForm';
import * as config from '../config/constants';
import { setupPage } from '../lib/navigation';
import IconLinkedIn from '../images/linkedin.svg';
import IconTwitter from '../images/twitter.svg';
import IconEmail from '../images/email.svg';
import type { Variants } from 'framer-motion';

/**
 * Animation variables for the top text.
 */
const VARIANTS_TEXT: Variants = {
	initial: {
		opacity: 0,
	},
	pageLoad: {
		opacity: 1,
		transition: { ease: `easeOut`, duration: config.ANIMATION_DURATION * 3, delay: 0.5 },
	},
	navigationExit: {
		opacity: 0,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
	navigationEntry: {
		opacity: 1,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
};

/**
 * Animation variables for the form.
 */
const VARIANTS_FORM: Variants = {
	initial: {
		opacity: 0,
	},
	pageLoad: {
		opacity: 1,
		transition: { ease: `easeOut`, duration: config.ANIMATION_DURATION * 3, delay: 0.5 },
	},
	navigationExit: {
		opacity: 0,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
	navigationEntry: {
		opacity: 1,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
};

const Page = () => {
	const data = useStaticQuery(graphql`
		query ContactPageQuery {
			site {
				siteMetadata {
					socialLinks {
						linkedIn
						twitter
						email
					}
				}
			}
		}
	`);
	const { socialLinks } = data.site.siteMetadata;
	const [ctrlAnimateTransition, onNavigationExit, onNavigationEntry] = setupPage(useEffect, useAnimation);

	return (
		<Layout
			pageTitle="Contact"
			pageDescription="Contact me for a project or just to chat!"
			pageImgUrl="https://joshuacole.me/images/me2.jpg"
			pageImgMime="image/jpeg"
			pageImgAlt="Photo of Josh"
			ctrlAnimateTransition={ctrlAnimateTransition}
			onNavigationExit={onNavigationExit}
			onNavigationEntry={onNavigationEntry}
			className="!pb-0"
		>
			<Section className="min-h-screen flex pt-20">
				<div className="my-8 mx-auto sm:m-auto flex flex-col">
					<motion.div
						initial="initial"
						animate={ctrlAnimateTransition}
						variants={VARIANTS_TEXT}
						className="text-white font-thin text-3xl text-center drop-shadow-2xl mb-5 opacity-0"
					>
						Let's talk...
					</motion.div>
					<div className="flex flex-col sm:flex-row justify-center items-center">
						<ContactButton
							ctrlAnimateTransition={ctrlAnimateTransition}
							direction="RIGHT"
							href={socialLinks.linkedIn}
							Icon={IconLinkedIn}
						/>
						<ContactButton
							ctrlAnimateTransition={ctrlAnimateTransition}
							direction="UP"
							href={socialLinks.twitter}
							Icon={IconTwitter}
						/>
						<ContactButton
							ctrlAnimateTransition={ctrlAnimateTransition}
							direction="LEFT"
							href={`mailto:${socialLinks.email}`}
							Icon={IconEmail}
						/>
					</div>
					<motion.div
						initial="initial"
						animate={ctrlAnimateTransition}
						variants={VARIANTS_TEXT}
						className="max-w-xl mt-8 bg-transparent bg-opacity-50 backdrop-blur-lg p-12 rounded-2xl drop-shadow-2xl"
					>
						<div className="text-white font-thin text-xl text-center drop-shadow-xl mb-5">
							Want to be notified when I'm next looking for work?
							<br className="hidden sm:block" />
							<span className="inline sm:hidden">&nbsp;</span>
							If so, subscribe to my work mailing list:
						</div>
						<MailchimpForm />
					</motion.div>
				</div>
			</Section>
		</Layout>
	);
};

export default Page;
