import * as React from 'react';

/**
 * Component properties.
 */
export interface IProps {
	className?: string;
}

/**
 * Component.
 */
export default function DotSeparator({ className = `` }: IProps) {
	return <span className={`mx-2 ${className}`}>·</span>;
}
