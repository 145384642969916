import * as React from 'react';
import Link from './Link';
import type { AnimationEventHandler } from '../lib/navigation';

/**
 * Component properties.
 */
export interface IProps {
	to: string;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	className?: string;
	onNavigationExit: AnimationEventHandler;
	onNavigationEntry: AnimationEventHandler;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function NavLink({
	to,
	onClick,
	className = ``,
	onNavigationExit,
	onNavigationEntry,
	children,
	...otherProps
}: IProps) {
	return (
		<Link
			to={to}
			onClick={onClick}
			className={`mx-4 my-auto text-xl no-underline pointer-events-auto ${className}`}
			activeClassName="text-sky-300"
			onNavigationEntry={onNavigationEntry}
			onNavigationExit={onNavigationExit}
			{...otherProps}
		>
			{children}
		</Link>
	);
}
