import * as React from 'react';
import { motion } from 'framer-motion';
import IconToggle from '../images/toggle.svg';
import { getButtonVariants } from '../lib/variants';
import { IS_BROWSER } from '../lib/utilities';
import type { AnimationControls } from 'framer-motion';

/**
 * Attempts to go back to the previous entry in the navigation history, otherwise goes to the given backTo URL.
 * @param backTo - The path to go back to if there is no history.
 */
function goBack(backTo: string): void {
	if (!IS_BROWSER) return;
	if (window.history.length <= 1) return void (window.location.pathname = backTo);
	window.history.back();
}

/**
 * Component properties.
 */
export interface IProps {
	ctrlAnimateTransition: AnimationControls;
	opacity?: number;
	backTo: string;
	className?: string;
}

/**
 * Component.
 */
export default function GoBackButton({ ctrlAnimateTransition, opacity = 0.5, backTo, className = `` }: IProps) {
	const variants = getButtonVariants(opacity);

	return (
		<motion.div
			initial="initial"
			animate={ctrlAnimateTransition}
			variants={variants}
			className={`text-left opacity-50 mt-4 sm:mt-8 ${className}`}
		>
			<a href="#" className="no-underline" onClick={() => goBack(backTo)}>
				<IconToggle className="inline w-4 h-4 fill-white align-middle rotate-180" />
				<span className="align-middle mx-1">Back</span>
			</a>
		</motion.div>
	);
}
