import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import NavLink from './NavLink';
import IconBurgerMenu from '../images/burger-menu.svg';
import * as config from '../config/constants';
import type { Variants, AnimationControls } from 'framer-motion';
import type { AnimationEventHandler } from '../lib/navigation';

/**
 * Animation variants for the navigation bar.
 */
const VARIANTS_NAV_BAR: Variants = {
	initial: {
		opacity: 0,
	},
	pageLoad: {
		opacity: 1,
		transition: { ease: `easeOut`, duration: config.ANIMATION_DURATION * 3, delay: 0.5 },
	},
	navigationExit: {
		opacity: 0,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
	navigationEntry: {
		opacity: 1,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
};

/**
 * Component properties.
 */
export interface IProps {
	ctrlAnimateTransition: AnimationControls;
	onNavigationExit: AnimationEventHandler;
	onNavigationEntry: AnimationEventHandler;
}

/**
 * Component.
 */
export default function NavigationBar({ ctrlAnimateTransition, onNavigationEntry, onNavigationExit }: IProps) {
	const [menuOpen, setMenuOpen] = useState(false);
	const toggleMenu = () => setMenuOpen(!menuOpen);
	const closeMenu = () => setMenuOpen(false);
	const outerCls = classnames(
		`opacity-0 sm:h-12 fixed left-0 top-0 w-full flex content-center bg-transparent backdrop-blur-xl z-40`,
		{
			'h-auto z-[100]': menuOpen,
			'h-12': !menuOpen,
		},
	);
	const innerCls = classnames(
		`sm:border-none border-solid border-l-[1px] border-l-slate-500 ml-4 my-6 sm:my-0 sm:h-full w-full flex flex-1 flex-col sm:flex-row justify-center`,
		{
			'visible h-auto px-1': menuOpen,
			'invisible sm:visible sm:flex': !menuOpen,
		},
	);

	return (
		<motion.nav initial="initial" animate={ctrlAnimateTransition} variants={VARIANTS_NAV_BAR} className={outerCls}>
			<NavLink
				to="/"
				onClick={closeMenu}
				onNavigationEntry={onNavigationEntry}
				onNavigationExit={onNavigationExit}
				className={`block pl-2 ml-2 font-logo font-thin tracking-wider drop-shadow-xl mt-2`}
			>
				JC
			</NavLink>
			<div className={innerCls}>
				<NavLink to="/" onClick={closeMenu} onNavigationEntry={onNavigationEntry} onNavigationExit={onNavigationExit}>
					Home
				</NavLink>
				<NavLink
					to="/projects"
					onClick={closeMenu}
					onNavigationEntry={onNavigationEntry}
					onNavigationExit={onNavigationExit}
				>
					Projects
				</NavLink>
				<NavLink
					to="/thoughts"
					onClick={closeMenu}
					onNavigationEntry={onNavigationEntry}
					onNavigationExit={onNavigationExit}
				>
					Thoughts
				</NavLink>
				<NavLink
					to="/contact"
					onClick={closeMenu}
					onNavigationEntry={onNavigationEntry}
					onNavigationExit={onNavigationExit}
				>
					Contact
				</NavLink>
			</div>
			<IconBurgerMenu className="sm:hidden w-8 h-8 mt-2 mx-4 fill-white cursor-pointer" onClick={toggleMenu} />
		</motion.nav>
	);
}
