import * as React from 'react';
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { setupPage } from '../lib/navigation';
import * as config from '../config/constants';
import DotSeparator from '../components/DotSeparator';

const VARIANTS_INTRO = {
	initial: {
		x: `-150%`,
		opacity: 0,
	},
	pageLoad: {
		x: `0`,
		opacity: 1,
		transition: { ease: `backInOut`, duration: config.ANIMATION_DURATION, delay: config.PAGE_LOAD_DURATION },
	},
	navigationExit: {
		x: `-150%`,
		opacity: 0,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
	navigationEntry: {
		x: `0`,
		opacity: 1,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
};
const VARIANTS_PHOTO = {
	initial: {
		x: `150%`,
		opacity: 0,
	},
	pageLoad: {
		x: `0`,
		opacity: 1,
		transition: { ease: `backInOut`, duration: config.ANIMATION_DURATION, delay: config.PAGE_LOAD_DURATION },
	},
	navigationExit: {
		x: `150%`,
		opacity: 0,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
	navigationEntry: {
		x: `0`,
		opacity: 1,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
};

const Page = () => {
	const [ctrlAnimateTransition, onNavigationExit, onNavigationEntry] = setupPage(useEffect, useAnimation);

	return (
		<Layout
			pageTitle={undefined}
			pageImgUrl="/images/me2.jpg"
			pageImgMime="image/jpeg"
			pageImgAlt="Photo of Josh"
			ctrlAnimateTransition={ctrlAnimateTransition}
			onNavigationExit={onNavigationExit}
			onNavigationEntry={onNavigationEntry}
			className="flex pt-8"
		>
			<Section className="flex flex-col xl:flex-row-reverse m-auto">
				<motion.div
					initial="initial"
					animate={ctrlAnimateTransition}
					variants={VARIANTS_PHOTO}
					className="opacity-0 flex-1 flex mx-auto mb-8"
				>
					<div className="m-auto xl:mr-0 rounded-full w-60 h-60 xl:w-64 xl:h-[28rem] 2xl:w-96 2xl:h-[38rem] bg-img-me bg-center bg-cover"></div>
				</motion.div>
				<motion.div
					initial="initial"
					animate={ctrlAnimateTransition}
					variants={VARIANTS_INTRO}
					className="opacity-0 my-auto xl:w-2/3 bg-transparent bg-opacity-50 backdrop-blur-lg md:mx-12 xl:mx-0 p-12 rounded-2xl drop-shadow-2xl text-white"
				>
					<div className="text-center lg:text-left text-5xl lg:text-7xl text-white whitespace-nowrap font-logo font-thin tracking-wider drop-shadow-xl">
						<span className="pl-6 -ml-6">Joshua Cole</span>
					</div>
					<div className="text-lg lg:text-xl text-white font-thin drop-shadow-2xl mt-8">
						Josh is an innovative, passionate, and detail-orientated <strong>technical leader</strong>,{' '}
						<strong>mentor</strong>, and <strong>speaker</strong>.<br />
						<br />
						He has over a decade of demonstrated success in building and leading high-performance, hybrid engineering
						and product teams and a proven track record managing multi-million pound projects.
						<br />
						<br />
						During his career he has delivered in multiple industries including digital healthcare, finance, education,
						real estate, telephony, and others.
					</div>
					<div className="text-white font-thin text-xs italic drop-shadow-2xl mt-8">
						<span>London</span>
						<DotSeparator />
						<span>UK</span>
					</div>
				</motion.div>
			</Section>
		</Layout>
	);
};

export default Page;
