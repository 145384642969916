import * as React from 'react';

/**
 * Component properties.
 */
export interface IProps {
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function ProjectCardTitle({ children }: IProps) {
	return <div className="font-semibold text-xl mb-4 inline-block">{children}</div>;
}
