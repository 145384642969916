import * as React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import * as config from '../config/constants';
import type { AnimationEventHandler } from '../lib/navigation';

/**
 * Component properties.
 */
export interface IProps {
	to: string;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	onNavigationExit: AnimationEventHandler;
	onNavigationEntry: AnimationEventHandler;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function Link({ to, onClick, onNavigationExit, onNavigationEntry, children, ...otherProps }) {
	return (
		<TransitionLink
			to={to}
			onClick={onClick}
			exit={{
				trigger: () => onNavigationExit(),
				length: config.NAVIGATION_DURATION,
			}}
			entry={{
				delay: config.NAVIGATION_DURATION,
				length: config.NAVIGATION_DURATION,
				trigger: () => onNavigationEntry(),
			}}
			partiallyActive={to !== `/`}
			{...otherProps}
		>
			{children}
		</TransitionLink>
	);
}
