import * as React from 'react';
import { useRef } from 'react';

const CLS_TD = `block sm:table-cell p-0 sm:p-2`;
const CLS_TD_LABEL = `text-center sm:text-right pt-4 pb-1`;
const CLS_INPUT_TEXT = `px-2 py-1 rounded-sm w-60 text-black bg-white bg-opacity-80 hover:bg-opacity-90 focus:bg-opacity-100 outline-blue-600 backdrop-blur-lg border-solid border-2 border-slate-200`;

/**
 * Component properties.
 */
export interface IProps {
	className?: string;
}

/**
 * Component.
 */
export default function MailchimpForm({ className = `` }: IProps) {
	const refEmail = useRef<HTMLInputElement>(null);
	const refFirstName = useRef<HTMLInputElement>(null);
	const refLastName = useRef<HTMLInputElement>(null);
	const refCompany = useRef<HTMLInputElement>(null);
	const refSubmit = useRef<HTMLInputElement>(null);
	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key !== `Enter`) return;
		event.preventDefault();
	};
	const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key !== `Enter`) return;
		event.preventDefault();

		if (!event.currentTarget.value.trim()) return;
		if (event.currentTarget === refEmail.current) refFirstName.current.focus();
		if (event.currentTarget === refFirstName.current) refLastName.current.focus();
		if (event.currentTarget === refLastName.current) refCompany.current.focus();
		if (event.currentTarget === refCompany.current) refSubmit.current.focus();
	};

	return (
		<form
			action="https://joshuacole.us16.list-manage.com/subscribe/post?u=f2e4945919cb63e38dbf2e00f&amp;id=28fbc1f1f2"
			method="post"
			id="mc-embedded-subscribe-form"
			name="mc-embedded-subscribe-form"
			target="_blank"
			className={`block w-full ${className}`}
		>
			<table className="mx-auto table-fixed border-collapse my-6 text-white">
				<tbody>
					<tr>
						<td className={`${CLS_TD} ${CLS_TD_LABEL}`}>
							<label htmlFor="mce-EMAIL">
								Email Address<span className="text-red-400">*</span>:
							</label>
						</td>
						<td className={CLS_TD}>
							<input
								ref={refEmail}
								type="email"
								name="EMAIL"
								id="mce-EMAIL"
								required
								className={CLS_INPUT_TEXT}
								onKeyDown={onKeyDown}
								onKeyUp={onKeyUp}
							/>
						</td>
					</tr>
					<tr>
						<td className={`${CLS_TD} ${CLS_TD_LABEL}`}>
							<label htmlFor="mce-FNAME">
								First Name<span className="text-red-400">*</span>:
							</label>
						</td>
						<td className={CLS_TD}>
							<input
								ref={refFirstName}
								type="text"
								name="FNAME"
								id="mce-FNAME"
								required
								className={CLS_INPUT_TEXT}
								onKeyDown={onKeyDown}
								onKeyUp={onKeyUp}
							/>
						</td>
					</tr>
					<tr>
						<td className={`${CLS_TD} ${CLS_TD_LABEL}`}>
							<label htmlFor="mce-LNAME">Last Name:</label>
						</td>
						<td className={CLS_TD}>
							<input
								ref={refLastName}
								type="text"
								name="LNAME"
								id="mce-LNAME"
								required
								className={CLS_INPUT_TEXT}
								onKeyDown={onKeyDown}
								onKeyUp={onKeyUp}
							/>
						</td>
					</tr>
					<tr>
						<td className={`${CLS_TD} ${CLS_TD_LABEL}`}>
							<label htmlFor="mce-AGENCYCOMP">Agency/Company:</label>
						</td>
						<td className={CLS_TD}>
							<input
								ref={refCompany}
								type="text"
								name="AGENCYCOMP"
								id="mce-AGENCYCOMP"
								className={CLS_INPUT_TEXT}
								onKeyDown={onKeyDown}
								onKeyUp={onKeyUp}
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<div className="absolute left-[-5000px]" aria-hidden="true">
				{/* This div and input is a bot buster. */}
				<input type="text" name="b_f2e4945919cb63e38dbf2e00f_28fbc1f1f2" tabIndex={-1} />
			</div>
			<input type="hidden" name="tags" value="3474144" />
			<input
				ref={refSubmit}
				type="submit"
				value="Subscribe"
				name="subscribe"
				id="mc-embedded-subscribe"
				className="block text-white outline-blue-600 mx-auto mt-12 sm:mt-8 rounded-lg bg-slate-600 px-3 py-2 cursor-pointer hover:opacity-90"
			/>
		</form>
	);
}
