import * as React from 'react';
import { motion } from 'framer-motion';
import IconToggle from '../images/toggle.svg';
import { scrollToTop } from '../lib/utilities';
import { getButtonVariants } from '../lib/variants';
import type { AnimationControls } from 'framer-motion';

/**
 * Component properties.
 */
export interface IProps {
	ctrlAnimateTransition: AnimationControls;
	opacity?: number;
	className?: string;
}

/**
 * Component.
 */
export default function GoToTopButton({ ctrlAnimateTransition, opacity = 0.5, className = `` }: IProps) {
	const variants = getButtonVariants(opacity);

	return (
		<motion.div
			initial="initial"
			animate={ctrlAnimateTransition}
			variants={variants}
			className={`text-center opacity-50 mt-4 sm:mt-8 ${className}`}
		>
			<a href="#" className="no-underline" onClick={scrollToTop}>
				<IconToggle className="inline w-4 h-4 fill-white align-middle -rotate-90" />
				<span className="align-middle mx-1">Go to top</span>
				<IconToggle className="inline w-4 h-4 fill-white align-middle -rotate-90" />
			</a>
		</motion.div>
	);
}
