import * as React from 'react';
import { motion } from 'framer-motion';
import * as config from '../config/constants';
import type { Variants, AnimationControls } from 'framer-motion';

/**
 * Represents the side of the screen the component should lean to.
 */
export type ProjectCardSide = `LEFT` | `RIGHT`;

const STARTING_OFFSET = -50;
const STAGGER_DELAY = 0.1;

/**
 * Returns the animation variants for the given weight of the component.
 * @param weight - An ordinal value.
 */
function getVariants(weight: number): Variants {
	const staggerDelay = STAGGER_DELAY * weight;

	return {
		initial: {
			y: STARTING_OFFSET,
			opacity: 0,
		},
		pageLoad: {
			y: 0,
			opacity: 1,
			transition: {
				ease: `easeOut`,
				duration: config.ANIMATION_DURATION,
				delay: config.PAGE_LOAD_DURATION + staggerDelay,
			},
		},
		navigationExit: {
			y: STARTING_OFFSET,
			opacity: 0,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 + staggerDelay },
		},
		navigationEntry: {
			y: 0,
			opacity: 1,
			transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 + staggerDelay },
		},
	};
}

/**
 * Component properties.
 */
export interface IProps {
	ctrlAnimateTransition: AnimationControls;
	weight: number;
	side: ProjectCardSide;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function ProjectCard({ ctrlAnimateTransition, weight, side, children }: IProps) {
	const cardOuterCls = side === `LEFT` ? `lg:self-start` : `lg:self-end`;
	const cardInnerCls = side === `LEFT` ? `lg:mr-10` : `lg:ml-10`;
	const lineCls = side === `LEFT` ? `lg:right-2` : `lg:left-2`;
	const orbCls = side === `LEFT` ? `lg:right-0 lg:-mr-4` : `lg:left-0 lg:-ml-4`;
	const variants = getVariants(weight);

	return (
		<motion.div
			initial="initial"
			animate={ctrlAnimateTransition}
			variants={variants}
			className={`opacity-0 lg:w-2/4 first:mt-0 last:mb-0 sm:first:mt-8 lg:last:mb-8 my-3 sm:mt-6 lg:-my-5 relative ${cardOuterCls}`}
		>
			<div className={`bg-blue-300 bg-opacity-20 backdrop-blur-lg p-5 rounded-lg text-white ${cardInnerCls}`}>
				{children}
			</div>
			<div
				className={`hidden lg:block absolute top-2/4 w-8 h-0 -mt-2px border-t-4 border-dotted border-blue-300 project-dots ${lineCls}`}
			></div>
			<div className={`hidden lg:block absolute top-2/4 w-8 h-8 -mt-4 rounded-full project-donut ${orbCls}`}></div>
		</motion.div>
	);
}
