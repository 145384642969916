import * as React from 'react';

export const IS_BROWSER = typeof window !== `undefined`; // True if we're in a browser context.
const INTL_DECIMAL = new Intl.NumberFormat(`en-GB`, { style: `decimal` });
const INTL_DATE = new Intl.DateTimeFormat(`en-GB`, { dateStyle: `short` });

/**
 * Scrolls the main view to the top smoothly.
 * @param event - The event that occurred.
 */
export function scrollToTop(event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
	event.preventDefault();
	if (!IS_BROWSER) return;
	window.scrollTo({ top: 0, behavior: `smooth` });
}

/**
 * Returns the given input formatted as a decimal in the UK locale.
 * @param input - The input to format.
 */
export function formatDecimal(input: number): string {
	return INTL_DECIMAL.format(input);
}

/**
 * Returns the given input formatted as a date string in the UK locale.
 * @param input - The input to format.
 */
export function formatDate(input: string | number | Date): string {
	const date = new Date(input);
	return INTL_DATE.format(date);
}
