import * as React from 'react';
import { motion } from 'framer-motion';
import * as config from '../config/constants';
import type { Variants, AnimationControls } from 'framer-motion';

const DURATION = config.ANIMATION_DURATION * 4;

/**
 * Animation variants for the time bar container element.
 */
const VARIANTS_CONTAINER: Variants = {
	initial: {
		opacity: 0,
	},
	pageLoad: {
		opacity: 1,
		transition: {
			ease: `easeIn`,
			duration: DURATION,
			delay: config.PAGE_LOAD_DURATION / 2,
		},
	},
	navigationExit: {
		opacity: 0,
		transition: { ease: `easeIn`, duration: DURATION, delay: 0 },
	},
	navigationEntry: {
		opacity: 1,
		transition: { ease: `easeIn`, duration: DURATION, delay: 0 },
	},
};

/**
 * Animation variants for the time bar itself.
 */
const VARIANTS_BAR: Variants = {
	initial: {
		height: 0,
	},
	pageLoad: {
		height: `100%`,
		transition: {
			ease: `easeIn`,
			duration: DURATION,
			delay: config.PAGE_LOAD_DURATION / 2,
		},
	},
	navigationExit: {
		height: `100%`,
		transition: { ease: `easeIn`, duration: DURATION, delay: 0 },
	},
	navigationEntry: {
		height: 0,
		transition: { ease: `easeIn`, duration: DURATION, delay: 0 },
	},
};

/**
 * Component properties.
 */
export interface IProps {
	ctrlAnimateTransition: AnimationControls;
}

/**
 * Component.
 */
export default function TimeBar({ ctrlAnimateTransition }: IProps) {
	return (
		<motion.div
			initial="initial"
			animate={ctrlAnimateTransition}
			variants={VARIANTS_CONTAINER}
			className="hidden lg:block absolute top-0 left-0 w-full h-full opacity-0"
		>
			<div className={`absolute top-20 -mt-2 left-2/4 w-2 -ml-1 h-3 bg-sky-600 clip-top-point`}></div>
			<div className="absolute top-20 bottom-10 left-2/4 w-2 -ml-1">
				<motion.div
					initial="initial"
					animate={ctrlAnimateTransition}
					variants={VARIANTS_BAR}
					className={`w-full h-0 bg-gradient-to-b from-sky-600 to-sky-800`}
				></motion.div>
			</div>
			<div className={`absolute bottom-10 left-2/4 w-6 -ml-3 h-2 rounded-sm bg-sky-800`}></div>
		</motion.div>
	);
}
