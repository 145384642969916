import { Link } from 'gatsby';
import * as React from 'react';

/**
 * Component properties.
 */
export interface IProps {
	slug: string;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function BlogPostCardTitle({ slug, children }: IProps) {
	return (
		<Link to={`/thoughts/${slug}`} className="font-semibold text-xl mb-4 inline-block">
			{children}
		</Link>
	);
}
