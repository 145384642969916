import * as React from 'react';
import { useEffect, useRef } from 'react';
import { IS_BROWSER } from '../lib/utilities';

/**
 * Component properties.
 */
export interface IProps {
	refScrollable?: React.RefObject<HTMLDivElement>;
	minMeasureWidthPc?: number;
	scrollThrottleMs?: number;
}

/**
 * Component.
 */
export default function ScrollProgressIndicator({
	refScrollable,
	minMeasureWidthPc = 0,
	scrollThrottleMs = 0,
}: IProps) {
	const scrollableEl = refScrollable?.current ?? (IS_BROWSER ? window : undefined);
	const refProgressMeasure = useRef<HTMLDivElement>();
	let lastKnownEventTarget;
	let timeoutId;

	const updateMeasure = () => {
		timeoutId = undefined;

		const el = lastKnownEventTarget.documentElement ?? lastKnownEventTarget ?? {};
		const { scrollTop, scrollHeight, clientHeight } = el;
		const scrollMax = scrollHeight - clientHeight;
		const measure = (scrollTop / scrollMax) * 100;
		const pc = Math.max(minMeasureWidthPc, measure);

		refProgressMeasure.current.style.width = `${pc}%`;
	};

	const onScroll = event => {
		lastKnownEventTarget = event.target;

		if (!scrollThrottleMs) return void updateMeasure();
		if (!timeoutId) timeoutId = setTimeout(updateMeasure, scrollThrottleMs);
	};

	useEffect(() => {
		scrollableEl?.addEventListener(`scroll`, onScroll);
		return () => scrollableEl?.removeEventListener(`scroll`, onScroll);
	}, []);

	return (
		<div className="fixed top-12 left-0 w-full h-1 bg-transparent z-50">
			<div
				ref={refProgressMeasure}
				className="h-full w-0 bg-sky-900 bg-opacity-90 backdrop-blur-xl transition-transform"
			></div>
		</div>
	);
}
