import * as React from 'react';
import { graphql } from 'gatsby';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { setupPage } from '../../lib/navigation';
import BlogPostCard from '../../components/BlogPostCard';
import BlogPostCardTitle from '../../components/BlogPostCardTitle';
import BlogPostPills from '../../components/BlogPostPills';
import { prepareNotionTags } from '../../lib/tags';
import { prepareNotionAuthors } from '../../lib/authors';
import GoToTopButton from '../../components/GoToTopButton';

function BlogPost({ node, weight, ctrlAnimateTransition }) {
	const firstPublished = node.properties.First_Published.value.start;
	const lastUpdated = node.properties.Last_Updated.value;
	const postAuthors = prepareNotionAuthors(node.properties.Author.value);
	const tags = prepareNotionTags(node.properties.Tags.value);

	return (
		<BlogPostCard ctrlAnimateTransition={ctrlAnimateTransition} weight={weight}>
			<BlogPostCardTitle slug={`${node.properties.Slug.value}-${node.id}`}>{node.title}</BlogPostCardTitle>
			<BlogPostPills
				postAuthors={postAuthors}
				firstPublished={firstPublished}
				lastUpdated={lastUpdated}
				timeToRead={node.childMarkdownRemark.timeToRead}
				wordCount={node.childMarkdownRemark.wordCount.words}
				tags={tags}
			/>
			<div className="text-lg" dangerouslySetInnerHTML={{ __html: node.childMarkdownRemark.excerpt }}></div>
		</BlogPostCard>
	);
}

const Page = ({
	data, // this prop will be injected by the GraphQL query below.
}) => {
	const [ctrlAnimateTransition, onNavigationExit, onNavigationEntry] = setupPage(useEffect, useAnimation);
	const posts = data.allNotion.edges;

	return (
		<Layout
			pageTitle="Thoughts"
			pageDescription="My thoughts and ramblings on software engineering, leadership, startups, and life."
			ctrlAnimateTransition={ctrlAnimateTransition}
			onNavigationExit={onNavigationExit}
			onNavigationEntry={onNavigationEntry}
		>
			<Section>
				{posts.map(({ node }, index) => (
					<BlogPost key={index} weight={index} node={node} ctrlAnimateTransition={ctrlAnimateTransition} />
				))}
				<GoToTopButton ctrlAnimateTransition={ctrlAnimateTransition} />
			</Section>
		</Layout>
	);
};

export const pageQuery = graphql`
	query {
		allNotion(
			filter: { archived: { eq: false }, properties: { Status: { value: { name: { eq: "Published" } } } } }
			sort: { order: DESC, fields: properties___First_Published___value___start }
		) {
			edges {
				node {
					id
					properties {
						First_Published {
							value {
								start
							}
						}
						Last_Updated {
							value
						}
						Slug {
							value
						}
						Tags {
							value {
								name
								color
							}
						}
						Author {
							value {
								id
							}
						}
					}
					title
					archived
					childMarkdownRemark {
						excerpt(format: PLAIN, pruneLength: 350, truncate: false)
						timeToRead
						wordCount {
							words
						}
					}
				}
			}
		}
	}
`;

export default Page;
