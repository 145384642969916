/**
 * Represents a Notion author object.
 */
export interface NotionAuthor {
	id: string;
}

/**
 * Represents a prepared author object.
 */
export interface Author {
	id: string;
	name: string;
}

/**
 * Mapping of Notion user IDs to user full names.
 */
export const NOTION_AUTHOR_MAP = {
	'47841b2e-0f7e-49ec-a58a-98fea74d27b6': `Josh Cole`,
};

/**
 * Takes the given array of Notion authors and returns an array of prepared author objects.
 * @param notionAuthors - An array of Notion authors.
 */
export function prepareNotionAuthors(notionAuthors: NotionAuthor[]): Author[] {
	return notionAuthors.map(({ id }) => ({ id, name: NOTION_AUTHOR_MAP[id] }));
}
