import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import NavigationBar from './NavigationBar';
import ScrollProgressIndicator from './ScrollProgressIndicator';
import type { AnimationControls } from 'framer-motion';
import type { AnimationEventHandler } from '../lib/navigation';

/**
 * Component properties.
 */
export interface IProps {
	pageTitle: string | undefined;
	pageDescription?: string;
	pageAuthor?: string;
	pageImgUrl?: string;
	pageImgMime?: string;
	pageImgAlt?: string;
	ogType?: string;
	metaTags?: React.ReactNode;
	className?: string;
	ctrlAnimateTransition: AnimationControls;
	onNavigationExit: AnimationEventHandler;
	onNavigationEntry: AnimationEventHandler;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function Layout({
	pageTitle,
	pageDescription,
	pageAuthor,
	pageImgUrl,
	pageImgMime,
	pageImgAlt,
	ogType,
	metaTags,
	className = ``,
	ctrlAnimateTransition,
	onNavigationExit,
	onNavigationEntry,
	children,
}: IProps) {
	const data = useStaticQuery(graphql`
		query HomePageQuery {
			site {
				siteMetadata {
					siteDomain
					siteUrl
					title
					description
					author {
						firstName
						lastName
					}
				}
			}
		}
	`);
	const {
		siteDomain,
		siteUrl,
		title: siteTitle,
		description: siteDescription,
		author: { firstName: authorFirstName, lastName: authorLastName },
	} = data.site.siteMetadata;
	const siteAuthor = `${authorFirstName} ${authorLastName}`;
	const sharingTitle = pageTitle || pageDescription || pageAuthor || siteAuthor;
	const sharingDescription = pageDescription || siteDescription;

	return (
		<>
			<Helmet title={`${siteTitle}${pageTitle ? ` - ${pageTitle}` : ``}`} defer={false}>
				<html lang="en-GB" />

				{/* General */}
				<meta name="description" content={pageDescription || siteDescription} />
				<meta name="author" content={pageAuthor || siteAuthor} />
				<link rel="icon" href="/images/favicon.jpg" type="image/jpeg" />

				{/* Apple */}
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content="black" />

				{/* Open Graph */}
				<meta property="og:url" content={siteUrl} />
				{pageAuthor && <meta property="article:author" content={pageAuthor} />}
				<meta property="og:title" content={sharingTitle} />
				<meta property="og:description" content={sharingDescription} />
				<meta property="og:type" content={ogType || `profile`} />
				<meta property="og:locale" content="en_GB" />
				<meta property="og:site_name" content={siteTitle} />
				<meta property="profile:first_name" content={authorFirstName} />
				<meta property="profile:last_name" content={authorLastName} />
				<meta property="profile:username" content="SaikoJosh" />
				<meta property="profile:gender" content="male" />
				{pageImgUrl && <meta property="og:image" content={pageImgUrl} />}
				{pageImgUrl && pageImgMime && <meta property="og:image:type" content={pageImgMime} />}
				{pageImgUrl && pageImgAlt && <meta property="og:image:alt" content={pageImgAlt} />}

				{/* Twitter */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:domain" content={siteDomain} />
				<meta name="twitter:url" content={siteUrl} />
				<meta name="twitter:title" content={sharingTitle} />
				<meta name="twitter:description" content={sharingDescription} />
				<meta name="twitter:site" content="@SaikoJosh" />
				<meta name="twitter:creator" content="@SaikoJosh" />
				{pageImgUrl && <meta name="twitter:image" content={pageImgUrl} />}

				{/* Miscellaneous */}
				{metaTags}
			</Helmet>
			<main className={`container mx-auto w-full min-h-screen pb-10 sm:pb-4 md:pb-0 ${className}`}>
				<NavigationBar
					ctrlAnimateTransition={ctrlAnimateTransition}
					onNavigationExit={onNavigationExit}
					onNavigationEntry={onNavigationEntry}
				/>
				<ScrollProgressIndicator />
				{children}
			</main>
		</>
	);
}
