import * as React from 'react';
import * as framerMotion from 'framer-motion';
import { IS_BROWSER } from './utilities';

/**
 * Represents a bound animation event handler function.
 */
export type AnimationEventHandler = () => void;

/**
 * Triggers the page load animations.
 * @param ctrlAnimateTransition - The animation controls.
 */
function onPageLoad(ctrlAnimateTransition: framerMotion.AnimationControls): void {
	ctrlAnimateTransition.start(`pageLoad`);
}

/**
 * Triggers the navigation exit animations.
 * @param ctrlAnimateTransition - The animation controls.
 */
function onNavigationExit(ctrlAnimateTransition: framerMotion.AnimationControls): void {
	ctrlAnimateTransition.start(`navigationExit`);
}

/**
 * Triggers the navigation entry animations.
 * @param ctrlAnimateTransition - The animation controls.
 */
function onNavigationEntry(ctrlAnimateTransition: framerMotion.AnimationControls): void {
	ctrlAnimateTransition.start(`navigationEntry`);
}

/**
 * Sets up and returns the animation controls and animation handler functions.
 * @param useEffect - The use effect hook from the component.
 * @param useAnimation - The use animation hook from the component.
 */
export function setupPage(
	useEffect: typeof React.useEffect,
	useAnimation: typeof framerMotion.useAnimation,
): [framerMotion.AnimationControls, AnimationEventHandler, AnimationEventHandler] {
	const ctrlAnimateTransition = useAnimation();
	const boundOnPageLoad: AnimationEventHandler = onPageLoad.bind(undefined, ctrlAnimateTransition);
	const boundOnNavigationExit: AnimationEventHandler = onNavigationExit.bind(undefined, ctrlAnimateTransition);
	const boundOnNavigationEntry: AnimationEventHandler = onNavigationEntry.bind(undefined, ctrlAnimateTransition);

	useEffect(() => {
		if (document.readyState === 'complete') return void boundOnPageLoad();
		if (!IS_BROWSER) return;

		window.addEventListener('load', boundOnPageLoad);
		return () => window.removeEventListener('load', boundOnPageLoad);
	}, []);

	return [ctrlAnimateTransition, boundOnNavigationExit, boundOnNavigationEntry];
}
