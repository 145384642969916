import * as React from 'react';
import { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { graphql } from 'gatsby';
import Prism from 'prismjs';
import { setupPage } from '../../lib/navigation';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import BlogPostPills from '../../components/BlogPostPills';
import { prepareNotionTags } from '../../lib/tags';
import { formatDate } from '../../lib/utilities';
import * as config from '../../config/constants';
import IconToggle from '../../images/toggle.svg';
import { prepareNotionAuthors } from '../../lib/authors';
import type { AnimationProps } from 'framer-motion';
import GoToTopButton from '../../components/GoToTopButton';
import GoBackButton from '../../components/GoBackButton';

const STARTING_OFFSET = -50;

const CONTAINER_VARIANTS: AnimationProps[`variants`] = {
	initial: {
		y: STARTING_OFFSET,
		opacity: 0,
	},
	pageLoad: {
		y: 0,
		opacity: 1,
		transition: {
			ease: `easeOut`,
			duration: config.ANIMATION_DURATION,
			delay: config.PAGE_LOAD_DURATION,
		},
	},
	navigationExit: {
		y: STARTING_OFFSET,
		opacity: 0,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
	navigationEntry: {
		y: 0,
		opacity: 1,
		transition: { ease: `linear`, duration: config.ANIMATION_DURATION, delay: 0 },
	},
};
const TOGGLE_ICON_VARIANTS: AnimationProps[`variants`] = {
	open: {
		rotate: 90,
		transition: {
			duration: config.ANIMATION_DURATION,
		},
	},
	closed: {
		rotate: 0,
		transition: {
			duration: config.ANIMATION_DURATION,
		},
	},
};
const TOC_VARIANTS: AnimationProps[`variants`] = {
	open: {
		height: `auto`,
		transition: {
			duration: config.ANIMATION_DURATION,
		},
	},
	closed: {
		height: 0,
		transition: {
			duration: config.ANIMATION_DURATION,
		},
	},
};

export default function Template({
	data, // this prop will be injected by the GraphQL query below.
}) {
	const [ctrlAnimateTransition, onNavigationExit, onNavigationEntry] = setupPage(useEffect, useAnimation);
	const [tocOpen, setTocOpen] = useState(false);
	const toggleToc = () => setTocOpen(!tocOpen);

	if (data.notion.properties.Status?.value.name !== `Published`) return <></>;

	const {
		title: postTitle,
		childMarkdownRemark: {
			excerpt,
			tableOfContents,
			timeToRead,
			wordCount: { words: wordCount },
			html,
		},
		properties: {
			First_Published: {
				value: { start: firstPublished },
			},
			Last_Updated: { value: lastUpdated },
			Tags: { value: notionTags },
			Author: { value: notionAuthors },
		},
	} = data.notion;
	const tags = prepareNotionTags(notionTags);
	const postAuthors = prepareNotionAuthors(notionAuthors);

	useEffect(() => {
		Prism.highlightAll();
	});

	const metaTags = [
		<meta key="article:published_time" property="article:published_time" content={formatDate(firstPublished)} />,
		<meta key="article:modified_time" property="article:modified_time" content={formatDate(lastUpdated)} />,
		...tags.map((tag, index) => <meta key={index} property="article:tag" content={tag.label} />),
	];

	return (
		<Layout
			pageTitle={postTitle}
			pageDescription={excerpt}
			pageAuthor={postAuthors[0].name}
			ogType="article"
			metaTags={metaTags}
			ctrlAnimateTransition={ctrlAnimateTransition}
			onNavigationExit={onNavigationExit}
			onNavigationEntry={onNavigationEntry}
		>
			<Section className="flex flex-col">
				<GoBackButton ctrlAnimateTransition={ctrlAnimateTransition} backTo="/thoughts" />
				<motion.article
					initial="initial"
					animate={ctrlAnimateTransition}
					variants={CONTAINER_VARIANTS}
					className="opacity-0 blog-post-prose mt-2 sm:mt-6 bg-blue-300 bg-opacity-20 backdrop-blur-lg p-5 rounded-lg text-white"
				>
					<h1>{postTitle}</h1>
					<BlogPostPills
						postAuthors={postAuthors}
						firstPublished={firstPublished}
						lastUpdated={lastUpdated}
						timeToRead={timeToRead}
						wordCount={wordCount}
						tags={tags}
					/>
					{/* <h2 className="cursor-pointer !mb-0 hover:opacity-80" onClick={toggleToc}>
						<motion.div
							initial="closed"
							animate={tocOpen ? `open` : `closed`}
							variants={TOGGLE_ICON_VARIANTS}
							className="inline-flex align-middle w-7 h-7 fill-white"
						>
							<IconToggle className="flex-1" />
						</motion.div>
						<span className="align-middle ml-2">Contents</span>
					</h2>
					<motion.div
						initial="closed"
						animate={tocOpen ? `open` : `closed`}
						variants={TOC_VARIANTS}
						className={`overflow-hidden`}
						dangerouslySetInnerHTML={{ __html: tableOfContents }}
					/> */}
					<div dangerouslySetInnerHTML={{ __html: html }} />
				</motion.article>
				<GoToTopButton ctrlAnimateTransition={ctrlAnimateTransition} />
			</Section>
		</Layout>
	);
}

export const pageQuery = graphql`
	query ($id: String!) {
		notion(id: { eq: $id }) {
			title
			childMarkdownRemark {
				excerpt(format: PLAIN, pruneLength: 200, truncate: false)
				tableOfContents
				html
				timeToRead
				wordCount {
					words
				}
			}
			properties {
				First_Published {
					value {
						start
					}
				}
				Last_Updated {
					value
				}
				Tags {
					value {
						name
						color
					}
				}
				Author {
					value {
						id
					}
				}
				Status {
					value {
						name
					}
				}
			}
		}
	}
`;
