import * as React from 'react';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import TimeBar from '../../components/TimeBar';
import ProjectCard from '../../components/ProjectCard';
import ProjectCardTitle from '../../components/ProjectCardTitle';
import ProjectCardDescription from '../../components/ProjectCardDescription';
import PillBar from '../../components/PillBar';
import Pill from '../../components/Pill';
import { setupPage } from '../../lib/navigation';
import IconCoins from '../../images/coins.svg';
import IconBrowser from '../../images/browser.svg';
import IconBank from '../../images/bank.svg';
import IconEthereum from '../../images/ethereum.svg';
import GoToTopButton from '../../components/GoToTopButton';

const PillEth = () => <Pill bgCol="bg-[#B7C2F3]">ETH</Pill>;
const PillFramerMotion = () => <Pill bgCol="bg-[#9C39E5]">Framer Motion</Pill>;
const PillGatsby = () => (
	<Pill bgCol="bg-[#5F3994]" fgCol="text-gray-200">
		Gatsby
	</Pill>
);
const PillGo = () => <Pill bgCol="bg-[#8CD2F9]">Go</Pill>;
const PillLinux = () => <Pill bgCol="bg-[#E7BA40]">Linux</Pill>;
const PillMonzoApi = () => <Pill bgCol="bg-[#9DB9A7]">Monzo API</Pill>;
const PillNvidia = () => <Pill bgCol="bg-[#85B637]">Nvidia</Pill>;
const PillReact = () => <Pill bgCol="bg-[#7ED8F7]">React</Pill>;
const PillRestful = () => <Pill bgCol="bg-emerald-500">RESTful</Pill>;
const PillRtx3090 = () => (
	<Pill bgCol="bg-[#5F5F5E]" fgCol="text-gray-200">
		RTX 3090
	</Pill>
);
const PillTailwind = () => <Pill bgCol="bg-blue-300">Tailwind</Pill>;
const PillTypeScript = () => (
	<Pill bgCol="bg-blue-900" fgCol="text-gray-200">
		TypeScript
	</Pill>
);
const PillWip = () => <Pill bgCol="bg-yellow-400">Work in Progress</Pill>;

const Page = () => {
	const [ctrlAnimateTransition, onNavigationExit, onNavigationEntry] = setupPage(useEffect, useAnimation);

	return (
		<Layout
			pageTitle="Projects"
			pageDescription="A collection of personal and side projects I have worked on over the years."
			ctrlAnimateTransition={ctrlAnimateTransition}
			onNavigationExit={onNavigationExit}
			onNavigationEntry={onNavigationEntry}
		>
			<Section className="flex flex-col">
				<TimeBar ctrlAnimateTransition={ctrlAnimateTransition} />
				<div className="flex-1 flex flex-col">
					<ProjectCard weight={1} ctrlAnimateTransition={ctrlAnimateTransition} side="LEFT">
						<ProjectCardTitle>
							<IconCoins className="inline h-8 mr-3" />
							Crypto Trading Algorithm
						</ProjectCardTitle>
						<PillBar>
							<PillWip />
						</PillBar>
						<ProjectCardDescription>
							My current project is a cryptocurrency trading algorithm. Previously I built an Ethereum mining rig as an
							experiment and used it to mine several different coins. I always knew it would be a short-term operation
							as proof-of-work mining comes to an end, but I wanted to continue earning passive income from cryptos. The
							way forward is a combination of earning rewards for owning various coins (proof-of-stake), and
							automatically trading coins as their currency pairs fluctuate.
						</ProjectCardDescription>
						<ProjectCardDescription>This is currently a work in progress - watch this space.</ProjectCardDescription>
					</ProjectCard>
					<ProjectCard weight={2} ctrlAnimateTransition={ctrlAnimateTransition} side="RIGHT">
						<ProjectCardTitle>
							<IconBrowser className="inline h-8 mr-3" />
							New Personal Website
						</ProjectCardTitle>
						<PillBar>
							<PillGatsby />
							<PillReact />
							<PillTypeScript />
							<PillTailwind />
							<PillFramerMotion />
						</PillBar>
						<ProjectCardDescription>
							Having not touched my personal website in several years it was time for a refresh! I had purposely kept my
							previous website very simple, with just a bit of HTML 5 and CSS 3 on a single page. This time I wanted
							something that was a bit more sophisticated, with animations and a blog, and a number of different pages.
							I decided to rebuild my site using{' '}
							<a href="https://www.gatsbyjs.com/" target="_blank">
								Gatsby
							</a>{' '}
							and TypeScript as these were technologies I already was familiar with.
						</ProjectCardDescription>
						<ProjectCardDescription>
							I would also be able to build a blog that worked for me, rather than being forced to use a paid blogging
							platform or a clunky legacy tool such as WordPress. Gatsby allowed me to write my blog in Markdown which
							was ideal and I could easily customise any functionality or design elements I didn't like. For animations,
							I used the{' '}
							<a href="https://www.framer.com/motion/" target="_blank">
								Framer Motion
							</a>{' '}
							library.
						</ProjectCardDescription>
						<ProjectCardDescription>
							Source code:{' '}
							<a href="https://gitlab.com/SaikoJosh/personal-website" target="_blank">
								GitLab
							</a>
						</ProjectCardDescription>
					</ProjectCard>
					<ProjectCard weight={3} ctrlAnimateTransition={ctrlAnimateTransition} side="LEFT">
						<ProjectCardTitle>
							<IconBank className="inline h-8 mr-3" />
							Monzo Money Manager
						</ProjectCardTitle>
						<PillBar>
							<PillGo />
							<PillMonzoApi />
							<PillRestful />
						</PillBar>
						<ProjectCardDescription>
							I'd been looking for a personal project to build in Go for a while, but in order for me to invest time
							into it, it needed to be something that would be useful in my everyday life. At the time, I had become
							frustrated with the limitations of managing my money in Monzo and across various pots. I knew Monzo had a
							customer-facing API open to all, so I decided to see what I could do with it.
						</ProjectCardDescription>
						<ProjectCardDescription>
							After some experimentation, my Go project ended up as an automated tool where I could set my goals (i.e.
							save up for this purchase, or pay off that credit card, etc) and have it manage my money for me. Each
							week, depending on how I was progressing towards my financial goals and what my spending had been like, my
							project would parcel out money into the various pots.
						</ProjectCardDescription>
						<ProjectCardDescription>
							I paired this with Monzo Premium's virtual cards, so I could only spend what was available in each pot as
							determined by my code. I setup pots for various purchases I wanted to make, pots for health, holidays,
							disposable income, bills, and so on. The next revision of this project will likely be something that
							analyses my spending and points out areas where I can make savings, i.e. on subscriptions I rarely use.
						</ProjectCardDescription>
					</ProjectCard>
					<ProjectCard weight={4} ctrlAnimateTransition={ctrlAnimateTransition} side="RIGHT">
						<ProjectCardTitle>
							<IconEthereum className="inline h-8 mr-3" />
							Crypto Mining Rig
						</ProjectCardTitle>
						<PillBar>
							<PillNvidia />
							<PillRtx3090 />
							<PillEth />
							<PillLinux />
						</PillBar>
						<ProjectCardDescription>
							During the Covid lockdowns I decided I wanted to learn more about how cryptocurrencies work and I figured
							the best way to do that was just to jump right in and start mining them. I also hoped to earn a little bit
							of passive income before the Ethereum 2.0 update (switching from proof-of-work to proof-of-stake) ended
							profitable mining on consumer-grade hardware for good.
						</ProjectCardDescription>
						<ProjectCardDescription>
							I built an open-air case out of wood from my local DIY store, and outfit it with 3x Nvidia RTX 3090 GPUs,
							low cost AMD CPU, MSI motherboard, and RAM, and a high-efficiency 1300W Antec Signature PSU. I also
							installed PSU riser boards so I could mount the GPUs vertically for better airflow and heat exhaust.
						</ProjectCardDescription>
						<ProjectCardDescription>
							Once built, I installed the mining OS{' '}
							<a href="https://hiveos.farm/" target="_blank">
								Hive
							</a>{' '}
							and configured my chosen mining software, GMiner. Hive provided a very useful dashboard giving me live
							information on the state of my mining rig, including GPU temperatures, fan speeds, errors, and so on. Over
							time, I was able to double the profitability of my rig by overclocking the GPUs, optimising power
							consumption, and providing it with cooler, ambient airflow.
						</ProjectCardDescription>
					</ProjectCard>
				</div>
			</Section>
			<GoToTopButton ctrlAnimateTransition={ctrlAnimateTransition} className="mt-4 sm:mt-0 lg:mt-10 pb-16" />
		</Layout>
	);
};

export default Page;
