/**
 * Represents a list of Notion tag colours.
 */
export const TAG_COLOURS = [
	`default`,
	`gray`,
	`brown`,
	`orange`,
	`yellow`,
	`green`,
	`blue`,
	`purple`,
	`pink`,
	`red`,
] as const;
export type TagColour = typeof TAG_COLOURS[number];

/**
 * Represents a mapping of Notion tag colours to HTML colour codes.
 */
export type TagColourNameDict = {
	[key in TagColour]: string;
};
export const TAG_COLOUR_CODES: TagColourNameDict = {
	default: `bg-gray-200`,
	gray: `bg-gray-400`,
	brown: `bg-yellow-700`,
	orange: `bg-orange-400`,
	yellow: `bg-yellow-400`,
	green: `bg-green-400`,
	blue: `bg-blue-400`,
	purple: `bg-purple-400`,
	pink: `bg-pink-400`,
	red: `bg-red-400`,
};

/**
 * Represents a prepared tag object.
 */
export interface Tag {
	label: string;
	colourName: string;
}

/**
 * Returns the corresponding HTML colour code for a given Notion tag colour.
 * @param colour - The Notion colour name.
 */
export function getTagColourName(colour: TagColour): string {
	return TAG_COLOUR_CODES[colour];
}

/**
 * Maps the given array of Notion tags to prepared tag objects.
 * @param notionTags - An array of Notion tags.
 */
export function prepareNotionTags(notionTags): Tag[] {
	const output: Tag[] = notionTags.map(notionTag => ({
		label: notionTag.name,
		colourName: getTagColourName(notionTag.color),
	}));

	return output;
}
