import * as React from 'react';

/**
 * Component properties.
 */
export interface IProps {
	className?: string;
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function PillBar({ className = ``, children }: IProps) {
	return <div className={`inline-block px-4 align-top ${className}`}>{children}</div>;
}
