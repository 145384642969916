import * as React from 'react';

/**
 * Component properties.
 */
export interface IProps {
	children: React.ReactNode;
}

/**
 * Component.
 */
export default function ProjectCardDescription({ children }: IProps) {
	return <div className="text-lg mt-3">{children}</div>;
}
